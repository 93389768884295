import { policyList } from "@/enums/permissions";
import { Customer, defaultCustomer } from "@/interfaces/customer";
import { Location, LocationSalesLimits } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { customerService } from "@/services/customer.service";
import { store } from "@/store";
import { Callback } from "@/types/types";
import { differenceInYears } from "date-fns";
import { HelixDatePickerComponent } from "helix-vue-components";
import { BooleanCheck } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./AddCustomer.template.vue";
const namespace: string = "CustomerModule";

interface ModalLabels {
  title: string;
  newButton: string;
  existingButton: string;
}

interface RoutesName {
  add: string;
  list: string;
}

const defaultLabels: ModalLabels = {
  title: "add",
  newButton: "customers_new",
  existingButton: "customers_list"
};

const defaultRoutesName: RoutesName = {
  add: "customers-add",
  list: "customers-list"
};

@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class AddCustomerComponent extends Vue {
  @Prop({ default: () => defaultLabels })
  public labels!: ModalLabels;
  @Prop({ default: () => defaultRoutesName })
  public routesName!: RoutesName;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  @Getter("limitConfig", { namespace: "AuthModule" })
  public limitConfig!: LocationSalesLimits[];
  @Action("saveRecreationalCustomer", { namespace })
  public saveCustomer!: Callback;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public isOlder: boolean = false;
  public hasCreateModifyPermission: boolean = true;
  public yearsAgo: number = 0;
  public canContinue: boolean = false;
  public action: string | undefined = "ACTION_BLOCK";
  protected birthdate: string | null = "";

  public get notMedical() {
    return this.currentLocation.location_type !== "RETAIL_MEDICAL";
  }

  public ageValidate() {
    if (this.birthdate) {
      this.yearsAgo = differenceInYears(new Date(), new Date(this.birthdate));
      let ageLimit: number = 16;
      const ageRule: LocationSalesLimits | null =
        this.currentLocation.location_type === "RETAIL_RECREATIONAL"
          ? customerService.getAgeRule("recreational")
          : customerService.getAgeRule("medicinal");
      if (ageRule) {
        ageLimit = Number(ageRule.configs[0].config.from);
        this.action = ageRule.action;
      }
      this.isOlder = this.yearsAgo >= ageLimit;
    }
  }

  public createNewCustomer() {
    const customer: Customer = {
      ...defaultCustomer,
      first_name: "RECREATIONAL",
      last_name: "CUSTOMER",
      type: "ANONYMOUS",
      birthday: this.birthdate
    };
    this.saveCustomer({ customer, reloadCustomers: true });
    this.close();
  }
  public mounted() {
    this.hasCreateModifyPermission = this.hasPermission(
      policyList.createModifyCustomers
    );
  }

  protected goTo(namedRoute: string): void {
    if (namedRoute === "customers-list") {
      store.commit("CustomerModule/setNavigateToCheckIn", true);
    }
    this.$router.push({ name: namedRoute });
    this.$emit("resolve");
  }

  protected close(): void {
    this.$emit("resolve");
  }
}
