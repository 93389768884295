import { RetailSettings } from "@/interfaces/retailSettings";
import { router } from "@/internal";
import { customerService } from "@/services/customer.service";
import debounce from "lodash/debounce";
import { boolean } from "mathjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./FiltersCheckIn.template.vue";
@Component({
  mixins: [Template]
})
export default class FiltersCheckInComponent extends Vue {
  @Prop({ default: false })
  public loading!: boolean;
  public callInFilters: string[] = ["call_in_pending", "call_in_filled"];
  public inStoreFilters: string[] = ["in_store_pending", "in_store_filled"];
  public filterSelected: { [key: string]: boolean } = {};
  public status: {
    attended: { show: boolean; enabled: boolean };
    checked_in: { show: boolean; enabled: boolean };
    online_pending: { show: boolean; enabled: boolean };
    online_filled: { show: boolean; enabled: boolean };
    order_saved: { show: boolean; enabled: boolean };
    call_in_pending: { show: boolean; enabled: boolean };
    call_in_filled: { show: boolean; enabled: boolean };
    in_store_pending: { show: boolean; enabled: boolean };
    in_store_filled: { show: boolean; enabled: boolean };
    [key: string]: { show: boolean; enabled: boolean };
  } = {
    attended: { show: true, enabled: true },
    checked_in: { show: true, enabled: true },
    online_pending: { show: true, enabled: true },
    online_filled: { show: true, enabled: true },
    order_saved: { show: true, enabled: true },
    call_in_pending: { show: false, enabled: true },
    call_in_filled: { show: false, enabled: true },
    in_store_filled: { show: false, enabled: true },
    in_store_pending: { show: false, enabled: true }
  };

  public filter = debounce(async status => {
    await customerService.filterInStore(status);
  }, 800);
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  protected retailSettings!: RetailSettings;

  @Watch("loading", { deep: true })
  public checkRoute() {
    if (!this.loading) {
      Object.entries(this.status).map(entry => {
        this.allowedFilters(entry[0]);
      });
    }
  }

  public getFiltersQuery() {
    Object.entries(this.status).map(entry => {
      if (entry[1].show) {
        this.filterSelected[entry[0]] = entry[1].enabled;
      }
    });
  }
  public async statusCheck(filter: string) {
    if (!this.loading) {
      this.status[filter].enabled = !this.status[filter].enabled;
    }
    this.getFiltersQuery();
    // Added Filters query in pagination
    this.$emit("storeFilterSelected", this.filterSelected);
    await customerService.filterInStore(this.filterSelected);
  }
  public allowedFilters(filter: string) {
    if (!this.loading) {
      if (this.callInFilters.indexOf(filter) > -1) {
        if (!this.retailSettings!.allow_call_in_pre_orders!) {
          this.status[filter].show = false;
        } else {
          this.status[filter].show = true;
        }
      } else if (this.inStoreFilters.indexOf(filter) > -1) {
        if (!this.retailSettings!.enable_in_store_order_fulfillment_flow!) {
          this.status[filter].show = false;
        } else {
          this.status[filter].show = true;
        }
      }
    }
  }

  public async mounted() {
    Object.entries(this.status).map(entry => {
      this.allowedFilters(entry[0]);
    });
  }
}
